import React from 'react';
import SEO from 'components/seo';

// Component
import TextImageBlock from 'components/organisms/Blocks/TextImageBlock/TextImageBlock';
import Hero from 'components/molecules/Hero/Hero';

// Data
import TerrasVloerenData from 'data/TerrasVloerenPage.json';

const TerrasVloerenPage = () => (
  <>
    <SEO title="Terrasvloeren" />
    <TextImageBlock
      data={TerrasVloerenData.waarom}
    />
    <Hero
      modifier="inPage"
      {...TerrasVloerenData.inPageHero}
    />
  </>
);
export default TerrasVloerenPage;
